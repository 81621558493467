<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/welcome" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <div class="max-width-mobile">
        <h2 style="font-weight: 500">{{ $t("User Access") }}</h2>
        <p>
          {{
            $t(
              "Did you know that it is not necessary to have an insurance policy with Caser to enjoy the advantages of Caser Cares?"
            )
          }}
        </p>
        <ion-button
          @click.prevent="openSSO"
          expand="block"
          size="medium"
          class="ion-margin-vertical"
          >{{ $t("I am a Caser Insured") }}</ion-button
        >
        <ion-button
          @click.prevent="router.push('/auth-local')"
          expand="block"
          size="medium"
          class="ion-margin-vertical"
          >{{ $t("I am not a Caser Insured") }}</ion-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonImg,
  alertController,
  getPlatforms
} from "@ionic/vue";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import config from "@/config";
export default {
  name: "AuthSelector",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonImg
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const isRealDevice =
      getPlatforms().includes("capitor") || getPlatforms().includes("cordova");
    const openSSO = async () => {
      if (!isRealDevice) {
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("SSO Login can only be done on real devices"),
          buttons: [t("Ok")]
        });
        alert.present();
        return;
      }
      const browser = InAppBrowser.create(config.url_sso, "_blank", {
        hideurlbar: "no",
        hidenavigationbuttons: "yes",
        hardwareback: "yes",
        location: "yes",
        presentationstyle: "fullscreen",
        footer: "yes",
        toolbar: "yes",
        toolbarcolor: "#FFFFFF",
        toolbarposition: "top",
        toolbartranslucent: "no",
        closebuttoncaption: "Cancelar"
      });
      browser.on("loadstop").subscribe(() => {
        browser.executeScript({
          code:
            "\
              if (typeof webkit.messageHandlers.cordova_iab !== \"undefined\") {\
                var selector = document.querySelector('meta[name=\"lt\"]');\
                if (selector) {\
                  var lt = JSON.stringify({lt: selector.getAttribute('content')});\
                  webkit.messageHandlers.cordova_iab.postMessage(lt);\
                }\
              }"
        });
      });
      browser.on("message").subscribe(async event => {
        if (event.data && event.data.lt) {
          browser.close();
          if (!(await store.dispatch("auth/token", event.data.lt))) {
            const alert = await alertController.create({
              cssClass: "error-alert",
              header: t("Error"),
              message: t("There was an error logging you in with the eCliente"),
              buttons: [t("Ok")]
            });
            alert.present();
            return;
          }
          if (store.state.auth.user) {
            router.push({ path: "/diary", replace: true });
          }
        }
      });
      return browser;
    };

    return {
      errorMessage: computed(() => store.state.auth.error),
      openSSO,
      isRealDevice,
      router
    };
  }
};
</script>
<style scoped>
p {
  margin: 2rem 0;
}
</style>
